* {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}
a {
  color: black;
  text-decoration: none;
}
.App {
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
}
.home-header {
  height: 20%;
  background-color: aquamarine;
  background-image: url('/public/bg-head.jpg');
  background-size: 100%;
  background-position: 0 -135px;
}
.home-content {
  height: 80%;
  background-color: aqua;
  background-image: url('/public/bg-first.jpg');
  overflow: hidden;
}